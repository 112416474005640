<template>
  <div :class="[props.wrapperClass, { 'ware-image--360': props.is360 }]">
    <AtomsImagesImage :alt="props.wareName" :src="url" :webp="!!props.suffix && props.suffix !== 'pl'" :class="props.class" class="aspect-square object-contain bg-white" />
  </div>
</template>
<script setup>

const utils = useUtils();

const props = defineProps({
  imageId: Number,
  suffix: String,
  wareName: String,
  class: String,
  wrapperClass: String,
  is360: Boolean
});

const url = computed(() => {

  const result = utils.getWareImageUrl(props.imageId, props.wareName, props.suffix);
  return result;

});

</script>
<style lang="postcss">
.ware-image {

  &--360 {

    @apply relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url('/img/360.png');
      background-repeat: no-repeat;
      background-position: center center;
      z-index: 1;
      cursor: pointer;
    }
  }
}
</style>